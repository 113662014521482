import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom"
import './styles/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { AuthProvider } from "react-oidc-context"

serviceWorker.unregister()

const saveOriginalUrl = () => {
  const isCallback = window.location.pathname.includes("/auth/callback")

  // No guardar si es el callback
  if (!isCallback)
    localStorage.setItem("originalUrl", window.location.href)
}

saveOriginalUrl()

ReactDOM.render(
  <AuthProvider
    authority = {process.env.REACT_APP_FUSIONAUTH_AUTHORITY}
    client_id = {process.env.REACT_APP_FUSIONAUTH_CLIENT_ID}
    logout_uri = {process.env.REACT_APP_FUSIONAUTH_LOGOUT_URI}
    redirect_uri = {process.env.REACT_APP_FUSIONAUTH_REDIRECT_URI}
    post_logout_redirect_uri = {process.env.REACT_APP_FUSIONAUTH_LOGOUT_REDIRECT_URI}
    
    //PARA REFRESH TOKENS:
    //automaticSilentRenew = {true} 
    //IMPORTANTE! ESTA URL TIENE QUE ESTAR EN LOS AUTHORIZED REDIRECTS DE FUSION AUTH
    //silent_redirect_uri = 'https://portalconcesionarios-qa.copec.cl'
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById("root")
)

