import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const FusionAuthCallback = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const originalUrl = localStorage.getItem("originalUrl") // Recuperar la URL original
    window.history.replaceState({}, document.title, "/") // Limpiar la URL

    if (originalUrl){
      localStorage.setItem("originalUrl", "/")
      window.location.href = originalUrl // Redirigir a la URL original
    }
    else
      window.location.href = "/" // Redirigir a la URL original

  }, [location, navigate])

  return <></>
};

export default FusionAuthCallback
